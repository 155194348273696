import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1960 from "../components/albums/Album1960";

const Gallery1960 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1960 />
        </div>
    );
};

export default Gallery1960;
