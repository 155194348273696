import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album2000 from "../components/albums/Album2000";

const Gallery2000 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album2000 />
        </div>
    );
};

export default Gallery2000;
