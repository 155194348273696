import React from "react";
import HeaderMenu from "../components/HeaderMenu";

import Vimeo from "../components/Vimeo";

const Videos = () => {

    return (
        <div>
            <HeaderMenu />
            <p> COMING SOON</p>
            <Vimeo />
        </div>
    );
};

export default Videos;
