import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1920 from "../components/albums/Album1920";

const Gallery1920 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1920 />
        </div>
    );
};

export default Gallery1920;
