import React, { useState, useEffect } from "react";

import { Home, Video, Recipes, Lakehouse, Gallery1920, Gallery1930, Gallery1940, Gallery1950, Gallery1960, Gallery1970, Gallery1980, Gallery1990, Gallery2000, Gallery2010, Gallery2020 } from "./pages/index";

import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { UserProvider, useUserContext } from "./components/context/user";
import Login from "./components/Login";


const AppRoutes = () => {
  const { user } = useUserContext();


  return (
    <Routes>
      {user ? (
        // Authenticated routes
        <>
          <Route path="/" element={<Home />} />
          <Route path="/video" element={<Video />} />
          <Route path="/recipes" element={<Recipes />} />
          <Route path="/lakehouse" element={<Lakehouse />} />
          <Route path="/1920" element={<Gallery1920 />} />
          <Route path="/1930" element={<Gallery1930 />} />
          <Route path="/1940" element={<Gallery1940 />} />
          <Route path="/1950" element={<Gallery1950 />} />
          <Route path="/1960" element={<Gallery1960 />} />
          <Route path="/1970" element={<Gallery1970 />} />
          <Route path="/1980" element={<Gallery1980 />} />
          <Route path="/1990" element={<Gallery1990 />} />
          <Route path="/2000" element={<Gallery2000 />} />
          <Route path="/2010" element={<Gallery2010 />} />
          <Route path="/2020" element={<Gallery2020 />} />

          {/* You can still allow a /login route if you like, but it might redirect */}
          <Route path="/login" element={<Login />} />
        </>
      ) : (
        // Unauthenticated routes
        <>
          {/* Login route */}
          <Route path="/login" element={<Login />} />
          {/* Catch-all: if not logged in, always show login */}
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};


const App = () => {
  return (
    <UserProvider>
      <AppRoutes />
    </UserProvider>
  );
};

export default App;