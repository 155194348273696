// components/context/user.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

export const UserContext = createContext();

// This provider will:
// 1) Check localStorage for a token on mount
// 2) Validate it with the server
// 3) Store the user in state if valid
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        async function getUser() {
            const token = localStorage.getItem('token');
            if (!token) return; // No token means user is not logged in

            try {
                const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/validate`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUser(res.data.user);
            } catch (error) {
                console.error(error);
                // If token is invalid/expired, remove it
                localStorage.removeItem('token');
            }
        }
        getUser();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Optional convenience hook for consuming the context
export const useUserContext = () => {
    return useContext(UserContext);
};


// export const useUserContext = () => useContext(UserContext)

// Default exports i.e export default HelloWorld; Can only have one per file
// Named exports i.e export const HelloWorld; Can have as many as you want
/**
 * What are the differences? To import a default export, import HelloWorld from './file', you can name it anything you want
 
 * To import a named export, you must put it in curly braces AND also name it exactly what it was exported as
 */