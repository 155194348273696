import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1950 from "../components/albums/Album1950";

const Gallery1920 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1950 />
        </div>
    );
};

export default Gallery1920;
