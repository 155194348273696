import React from "react";

import Home_m from "../components/Home_m"


const Home = () => {
    // const username = localStorage.getItem("username") || "Shannon";
    // console.log("sk checkpoint", username);

    return (
        <div>
            <Home_m />
        </div>
    );
};

export default Home;
