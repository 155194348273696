import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Like from "./Like";

function HighlightGallery() {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/s3/get-highlights`);
                setImages(response.data.presignedUrls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }
        fetchImages();
    }, []);

    // Slideshow
    useEffect(() => {
        if (images.length === 0) return;
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000);
        return () => clearInterval(interval);
    }, [images]);

    // Reset imageLoaded whenever currentIndex changes
    useEffect(() => {
        setImageLoaded(false);
    }, [currentIndex]);

    // Dot navigation
    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="highlight-gallery">
            {images.length > 0 && (
                <>

                    <img
                        src={images[currentIndex].url}
                        alt={`Slide ${currentIndex}`}
                        style={{ width: '100%' }}
                        onLoad={() => setImageLoaded(true)}
                    />

                    {/* Render description and location only after image has loaded */}
                    {imageLoaded && (
                        <>
                            <div style={{ marginTop: '5px', fontStyle: 'italic' }}>
                                {images[currentIndex].description}
                            </div>
                            <div className="location-text">
                                {images[currentIndex].location}
                            </div>
                        </>
                    )}

                    <Like />
                </>
            )
            }

            {/* Dots for navigation */}
            <div
                className="dots-container"
                style={{ textAlign: 'center', marginTop: '10px' }}
            >
                {images.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                        style={{
                            cursor: 'pointer',
                            margin: '0 4px',
                            backgroundColor: index === currentIndex ? '#007BA7' : '#bbb',
                            borderRadius: '50%',
                            display: 'inline-block',
                            width: '10px',
                            height: '10px'
                        }}
                    />
                ))}
            </div>
        </div >
    );
}

export default HighlightGallery;
