import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LuDownload } from "react-icons/lu";
import '../sk.css';

const Album1920 = () => {
    const [coverImage, setCoverImage] = useState();
    const [images, setImages] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const [imagesResponse, decadesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/s3/get-1920`),
                    axios.get(`${process.env.REACT_APP_SERVER_URL}/s3/get-decades`)
                ]);

                setImages(imagesResponse.data.presignedUrls);

                // If you expect only one cover image, you can use .find()
                const cover = decadesResponse.data.presignedUrls.find(url => url.includes('1920.png'));
                setCoverImage(cover);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }
        fetchData();
    }, []);

    const handleDownload = async (url, index, event) => {
        event.preventDefault();
        if (window.confirm("Do you want to download this image?")) {
            try {
                // Use fetch instead of axios to get the image blob
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const blob = await response.blob();

                // Determine file extension from the blob type (default to jpg)
                let fileExtension = 'jpg';
                if (blob.type) {
                    const matches = blob.type.match(/image\/(\w+)/);
                    if (matches && matches[1]) {
                        fileExtension = matches[1];
                    }
                }

                // Create a blob URL and trigger download
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `image-${index}.${fileExtension}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        }
    };

    return (
        <div>
            <img src={coverImage} alt="1920s" className="decade-cover-top" />

            <div className="image-grid">
                {images.map((image, index) => (
                    <div key={index} className="image-container">

                        <img src={image.url} alt={`Image ${index}`} className="grid-image" />

                        <div style={{ marginTop: '5px', fontStyle: 'italic' }}>{image.description}</div>
                        <div className="location-text">{image.location}</div>

                        {/* <button onClick={(e) => handleDownload(image.url, index, e)}
                            className="download-icon"><LuDownload size={24} /> </button> */}
                    </div>
                ))}

            </div>
        </div>
    );
};

export default Album1920;
