import React, { useState } from "react";
import HeaderMenu from './HeaderMenu';
import HighlightGallery from "./HighlightGallery";
import DecadeList from "./DecadeList";

//aesthetics
import './sk.css'

const Home_m = () => {

    const [searchTerm, setSearchTerm] = useState("");

    return (
        <div >
            <HeaderMenu />

            <div style={{ marginBottom: '20px' }}>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-box"
                        placeholder="Search...coming soon"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>


                <HighlightGallery style={{ textAlign: 'center' }} />

                <div style={{ border: '2px solid #EC5800', borderRadius: '1px', margin: 'auto', lineHeight: '.8', letterSpacing: '2px', maxWidth: '500px', textAlign: 'center', padding: '5px 0px 5px 0px' }}>

                    <div>
                        <div className="browse-box" style={{ color: '#EC5800' }}>COMING SOON: BROWSE</div>
                        <div className="browse-box-lower">by NAME • by LOCATION • by OCCASION</div>
                    </div>
                </div>
            </div>

            <DecadeList />

        </div>
    )
}

export default Home_m

