import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album2010 from "../components/albums/Album2010";

const Gallery2010 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album2010 />
        </div>
    );
};

export default Gallery2010;
