import React, { useEffect, useState } from 'react';
import axios from 'axios';



function DecadeList() {
    const [images, setImages] = useState([]);


    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/s3/get-decades`);
                setImages(response.data.presignedUrls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        }
        fetchImages();
    }, []);

    // Parse the decade from the presigned URL
    const getDecadeFromUrl = (url) => {
        // Strip off any query params
        const [path] = url.split('?');
        // e.g. "https://bucket.s3.amazonaws.com/decadeCovers/1920.png"
        // Split by '/' to get the last segment
        const segments = path.split('/');
        // The last segment is "1920.png"
        const fileName = segments[segments.length - 1];
        // Remove the file extension (".png")
        return fileName.replace('.png', '');
    };

    // Handle click event to navigate
    const handleImageClick = (url) => {
        const decade = getDecadeFromUrl(url);
        // Navigate to /1920, for example
        window.location.href = `/${decade}`;
    };


    return (
        <div style={{ textAlign: 'center' }}  >
            {
                images.map((url, index) => (
                    <img key={index} src={url} alt={`Decade ${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleImageClick(url)} className="decade-cover" />
                ))
            }
        </div >
    );
}

export default DecadeList;
