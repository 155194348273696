import React from 'react';
import ReactPlayer from 'react-player/vimeo';

function Vimeo() {
    return (
        <div className="video-container">
            <ReactPlayer
                url="https://vimeo.com/1034263042/80b6ea4b90?ts=0&share=copy"
                controls
                width="100%"
                height="100%"
            />
        </div>
    );
}

export default Vimeo;


{/* <div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1034263042?h=80b6ea4b90&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Ken &amp; Sue 25th"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */ }