import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1940 from "../components/albums/Album1940";

const Gallery1920 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1940 />
        </div>
    );
};

export default Gallery1920;
