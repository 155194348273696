import React from "react";
import HeaderMenu from "../components/HeaderMenu";

import Album2020 from "../components/albums/Album2020";

const Gallery1920 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album2020 />
        </div>
    );
};

export default Gallery1920;
