import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1970 from "../components/albums/Album1970";

const Gallery1970 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1970 />
        </div>
    );
};

export default Gallery1970;
