import React from "react";
import { useNavigate } from "react-router-dom";
import { BsFillHouseHeartFill } from "react-icons/bs";

import { RiCake3Fill } from "react-icons/ri";
import { FaVideo } from "react-icons/fa";
import { MdOutlineMonochromePhotos } from "react-icons/md";

import '../components/sk.css'
import '../components/header.css'

const HeaderMenu = () => {

    const navigate = useNavigate();

    const ClickToHome = () => {
        navigate("/", { replace: true });
        window.location.reload();
    };

    const ClickToVideo = () => {
        navigate("/video", { replace: true });
        window.location.reload();
    };

    const ClickToRecipes = () => {
        navigate("/recipes", { replace: true });
        window.location.reload();
    };

    const ClickToLakehouse = () => {
        navigate("/lakehouse", { replace: true });
        window.location.reload();
    };

    return (
        <div>
            <div className="top-menu">
                <div className="menu-item" onClick={ClickToHome}>
                    <MdOutlineMonochromePhotos className="menu-icon" />
                    <span className="menu-label">Photos</span>
                </div>
                <div className="menu-item" onClick={ClickToVideo}>
                    <FaVideo className="menu-icon" />
                    <span className="menu-label">Videos</span>
                </div>
                <div className="menu-item" onClick={ClickToRecipes}>
                    <RiCake3Fill className="menu-icon" />
                    <span className="menu-label">Recipes</span>
                </div>
                <div className="menu-item" onClick={ClickToLakehouse}>
                    <BsFillHouseHeartFill className="menu-icon" />
                    <span className="menu-label">Lakehouse</span>
                </div>
            </div>



        </div>
    )
}
export default HeaderMenu