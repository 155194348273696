import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1990 from "../components/albums/Album1990";

const Gallery1990 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1990 />
        </div>
    );
};

export default Gallery1990;
