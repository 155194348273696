import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import { FaRegCalendarAlt } from "react-icons/fa";

const Lakehouse = () => {
    const url = "https://my.cozi.com/signin";

    const handleCalendarClick = () => {
        // Opens in a new tab/window
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div>
            <HeaderMenu />

            <div className="calendar" onClick={handleCalendarClick}>
                <FaRegCalendarAlt size="17em" />
                <p>COZI CAL</p>
            </div>
        </div>
    );
};

export default Lakehouse;
