import React from "react";
import HeaderMenu from "../components/HeaderMenu";

const Recipes = () => {

    return (
        <div>
            <HeaderMenu />
            <p> RECIPES COMING SOON</p>
        </div>
    );
};

export default Recipes;
