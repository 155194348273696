import React, { useState } from "react";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeart } from "react-icons/io5";

const Like = () => {
    const [isLiked, setIsLiked] = useState(false);

    return (
        <div style={{ margin: '10px', textAlign: 'right' }}>
            <div onClick={() => setIsLiked(!isLiked)} style={{ cursor: "pointer" }}>
                {isLiked ? <IoHeart color="red" /> : <IoMdHeartEmpty />}
            </div>
            <div>
            </div>
        </div>

    );
};

export default Like;