// components/Login.js
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "./context/user";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { setUser } = useUserContext();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });

            const res = await response.json();
            console.log("SERVER URL:", process.env.REACT_APP_SERVER_URL);

            console.log(res);

            if (res.message === 'Email not found') {
                setErrorMessage('Invalid credentials, please try again.');
            } else {
                // Save token to localStorage
                localStorage.setItem('token', res.token);
                // Update user in context
                setUser(res.user);
                // Navigate to home page
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error logging in. Please try again.');
        }
    };

    return (
        <div style={{ margin: "1em" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <form onSubmit={handleLogin}>
                    <div style={{ marginBottom: "1em", color: "#ab966d" }}>
                        <label htmlFor="username">Username: </label>
                        <input
                            type="text"
                            id="username"
                            autoComplete="off"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>

                    <div style={{ marginBottom: "1em", color: "#ab966d" }}>
                        <label htmlFor="password">Password: </label>
                        <input
                            type="password"
                            id="password"
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button className="loginButton">Sign In</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
