import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import Album1980 from "../components/albums/Album1980";

const Gallery1980 = () => {

    return (
        <div>
            <HeaderMenu />
            <Album1980 />
        </div>
    );
};

export default Gallery1980;
